<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="#23303D"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14 14.99H2a1 1 0 0 1-1-.998V1.999A1 1 0 0 1 2 1h12a1 1 0 0 1 1 1v11.992a1 1 0 0 1-1 .999z"
  /></g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
